<script setup>
import marketInsiderLogo from "@images/front-pages/landing/mentioned/market-insider.png";
import yahooLogo from "@images/front-pages/landing/mentioned/yahoo-logo.png";
import cryptoUnfoldedLogo from "@images/front-pages/landing/mentioned/crypto-unfolded-logo.png";
import morningStarLogo from "@images/front-pages/landing/mentioned/morning-star-logo.png";
import cryptoPressLogo from "@images/front-pages/landing/mentioned/crypto-press-logo.png";
import bitcoinNewsLogo from "@images/front-pages/landing/mentioned/bitcoin-news.png";

const config = useRuntimeConfig();
const yahooLink = config.public.mentionedLinksYahoo;
const bitcoinNewsLink = config.public.mentionedLinksBitcoinNews;
const cryptoUnfoldedLink = config.public.mentionedLinksCryptoUnfolded;
const morningStarLink = config.public.mentionedLinksMorningStar;
const cryptoPressLink = config.public.mentionedLinksCryptoPress;

const navigateTo = (url) => {
  window.open(url, "_blank", "noopener,noreferrer");
};
</script>
<template>
  <div class="mx-8 mx-md-16 pt-sm-16">
    <VRow class="text-ltrenovate text-subtitle-1">
      <VCol>
        <span>[ 05 ]</span>
      </VCol>
      <VCol>
        <span class="d-flex justify-end text-uppercase text-no-wrap">
          [ {{ $t("mentioned.our_coverage") }} ]
        </span>
      </VCol>
    </VRow>
    <div class="my-6 my-md-10">
      <span class="text-h3 text-geomanist">
        {{ $t("mentioned.zchains_was_mentioned_on") }}
      </span>
    </div>

    <VRow class="mt-md-16">
      <VCol
        cols="6"
        sm="4"
        md="2"
        class="align-center d-flex red-divider my-16 my-md-0"
      >
        <VImg
          :src="yahooLogo"
          width="96"
          height="49"
          class="w-100 cursor-pointer"
          @click="navigateTo(yahooLink)"
        />
      </VCol>

      <VCol
        cols="6"
        sm="4"
        md="2"
        class="align-center d-flex red-divider my-16 my-md-0"
      >
        <VImg
          :src="bitcoinNewsLogo"
          width="130"
          height="20"
          class="w-100 cursor-pointer"
          @click="navigateTo(bitcoinNewsLink)"
        />
      </VCol>

      <VCol
        cols="6"
        sm="4"
        md="2"
        class="align-center d-flex red-divider mb-16 my-sm-16 my-md-0"
      >
        <VImg
          :src="morningStarLogo"
          width="116"
          height="26"
          class="w-100 cursor-pointer"
          @click="navigateTo(morningStarLink)"
        />
      </VCol>

      <VCol cols="6" sm="4" md="2" class="align-center d-flex red-divider">
        <VImg
          :src="cryptoPressLogo"
          width="140"
          height="20"
          class="w-100 cursor-pointer"
          @click="navigateTo(cryptoPressLink)"
        />
      </VCol>

      <VCol
        cols="6"
        sm="4"
        md="2"
        class="align-center d-flex red-divider mb-16 mb-md-0"
      >
        <VImg
          :src="cryptoUnfoldedLogo"
          width="149"
          height="20"
          class="w-100 cursor-pointer"
          @click="navigateTo(cryptoUnfoldedLink)"
        />
      </VCol>

      <VCol cols="6" sm="4" md="2" class="align-center d-flex md-red-divider">
        <VImg
          :src="marketInsiderLogo"
          width="140"
          height="35"
          class="w-100 cursor-pointer"
          @click="navigateTo('https://markets.businessinsider.com/news/stocks/introducing-fractionz-the-first-token-launchpad-on-zchains-1033727497')"
        />
      </VCol>
    </VRow>
  </div>
</template>

<style scoped>
.red-divider {
  border-right: 6px double #e10600;
  height: 70px;
}

.md-red-divider {
  @media (max-width: 959px) {
    border-right: 6px double #e10600;
    height: 70px;
  }
}
</style>
